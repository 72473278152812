import * as React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Link } from "gatsby";

import * as styles from "./index.module.scss";
import underlineImage from "../../../../../../static/underline.svg";

import ROUTES_OUT from "../../../../../constants/routesOut";

const TariffSlide = ({ cardLabel, title, subTitle, cost, featuresList }) => {
  const domain =
    process.env.GATSBY_FRONT_DOMAIN || "https://app.stage.fearn.co";

  // const interimLink = process.env.FEARN_WAITLIST_LINK;
  const interimLink = "https://waitlist.stage.fearn.co";

  return (
    <div className={styles.wrapper}>
      {cardLabel && <span className={styles.cardLabel}>{cardLabel}</span>}
      <div
        className={classnames({
          [styles.titleWrapper]: !!cardLabel,
          [[styles.titleWrapperWithMargin]]: !cardLabel,
        })}
      >
        <span className={styles.title}>{title}</span>
        <span className={styles.subTitle}>{subTitle}</span>
      </div>
      <div className={styles.costWrapper}>
        <span className={styles.currencySymbol}>$</span>
        <span className={styles.cost}>{cost} </span>
        <span className={styles.perMonth}>{"/ month"}</span>
        <img
          className={styles.underline}
          src={underlineImage}
          alt="Underline"
        />
      </div>
      <div className={styles.featuresList}>
        <ul>
          {featuresList.map((feature, index) => (
            <li key={index}>{feature}</li>
          ))}
        </ul>
      </div>
      <div className={styles.buttonWrapper}>
        <Link to={interimLink}>
          <button className={styles.button} type="button">
            Try Free
          </button>
        </Link>
      </div>
    </div>
  );
};

TariffSlide.propTypes = {
  cardLabel: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  cost: PropTypes.string,
  featuresList: PropTypes.array,
};

export default TariffSlide;
