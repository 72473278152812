import * as React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";

import * as styles from "./index.module.scss";

import TARIFFS_LIST from "../../constants/Pricing";
import TariffSlide from "../../sections/Pricing/Tariffs/components/TariffSlide";

const CarouselPricing = () => {
  const sliderRef = React.useRef({
    activeIndex: 0,
    slideNext: () => {},
    slidePrev: () => {},
  });

  const onNextSlide = React.useCallback(() => {
    sliderRef.current.slideNext();
  }, []);

  const onPrevSlice = React.useCallback(() => {
    sliderRef.current.slidePrev();
  }, []);

  return (
    <div className={styles.swiperWrapper}>
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        onSwiper={(swiper) => (sliderRef.current = swiper)}
        ref={sliderRef}
      >
        {TARIFFS_LIST.map((tariff, index) => (
          <SwiperSlide>
            <TariffSlide
              cardLabel={tariff?.cartLabel}
              title={tariff.title}
              subTitle={tariff.subTitle}
              cost={tariff.cost}
              featuresList={tariff.featuresList}
              key={index}
              onPrev={onPrevSlice}
              onNext={onNextSlide}
            />
          </SwiperSlide>
        ))}

        <div className={styles.buttonContainer}>
          <button className={styles.button} onClick={onPrevSlice}></button>
          <button className={styles.activeButton}></button>
          <button className={styles.button} onClick={onNextSlide}></button>
        </div>
      </Swiper>
    </div>
  );
};

export default CarouselPricing;
